import Cookies from 'js-cookie';
import { CURRENT_DOMAIN } from '../constants';

export default function clearStorage() {
    clearCookies();
    clearLocalStorage();
}

function clearCookies() {
    const domainList = [window.location.hostname, CURRENT_DOMAIN];
    // suppression des cookies
    const cookiesToKeep = ['preferenceWebPages'];
    Object.keys(Cookies.get()).forEach((key) => {
        if (!cookiesToKeep.includes(key)) {
            // on supprimer les coookies dans l'ancien domaine et le current
            // on a ajouté le . dans le nouveau domain pour éviter les erreurs avec le back end
            domainList.map((domainName) => {
                Cookies.remove(key, { domain: domainName });
            });
        }
    });
}

function clearLocalStorage() {
    const storageKeysToRemove = [
        'user',
        'currency_symbol',
        'access',
        'refresh',
    ];
    storageKeysToRemove.forEach((key) => localStorage.removeItem(key));
}
