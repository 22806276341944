import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BurgerMenu from './burgerMenu/BurgerMenu';
import LeftSideBar from './leftSideBar/LeftSideBar';
import BurgerButton from './burgerMenu/BurgerButton';
import RightSideBar from './rightSideBar/RightSideBar';
import menuGenerate from 'ck_commun/src/app/modUtils/functions/menuGenerate';
import Sider from 'ck_commun/src/app/modUtils/components/componentsLibrary/layout/Sider';
import Layout from 'ck_commun/src/app/modUtils/components/componentsLibrary/layout/Layout';
import Content from 'ck_commun/src/app/modUtils/components/componentsLibrary/layout/Content';

export default function MainLayout({ isProd, applogo, srcImg, appShortName }) {
    const { i18n } = useTranslation();
    const [menuItems, setMenuItems] = useState([]);
    const [collapsedLeft, setCollapsedLeft] = useState(false);
    const [collapsedRight, setCollapsedRight] = useState(false);
    const [collapsedBurger, setCollapsedBurger] = useState(false);
    const [menuBurgerIsVisible, setMenuBurgerIsVisible] = useState(
        window.innerWidth < 768,
    );
    const { groupId, selectedGroup } = useSelector((state) => state.manager);
    const permissions = selectedGroup?.permissions;
    const groupPermissions = selectedGroup?.plan?.permissions;

    // Générer les éléments du menu à partir de `menuGenerate` avec les bonnes données
    useEffect(() => {
        const items = menuGenerate(
            isProd,
            srcImg,
            permissions,
            groupPermissions,
        );
        setMenuItems(items);
    }, [groupId, i18n.language]);

    useEffect(() => {
        const handleResize = () => {
            setMenuBurgerIsVisible(window.innerWidth < 768);
        };

        // Ajouter un écouteur d'événements pour la redimension
        window.addEventListener('resize', handleResize);

        // Nettoyage de l'écouteur d'événements
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleMobileMenu = () => {
        setCollapsedBurger(!collapsedBurger);
    };

    return (
        <Layout>
            {menuBurgerIsVisible ? (
                <>
                    <BurgerButton onClick={toggleMobileMenu} />

                    <div
                        className={`${collapsedBurger ? 'block md:hidden' : 'hidden'} absolute z-[100]`}
                    >
                        <BurgerMenu
                            collapsed={false}
                            menuItems={menuItems}
                            toggleMobileMenu={toggleMobileMenu}
                        />
                    </div>
                </>
            ) : (
                <Sider
                    position='left'
                    collapsed={collapsedLeft}
                    setCollapsed={setCollapsedLeft}
                >
                    <LeftSideBar
                        collapsed={collapsedLeft}
                        menuItems={menuItems}
                        appName={appShortName}
                        applogo={applogo}
                    />
                </Sider>
            )}

            <Content>
                <Outlet />
            </Content>

            {!menuBurgerIsVisible && (
                <Sider
                    position='right'
                    collapsed={collapsedRight}
                    setCollapsed={setCollapsedRight}
                    collapseWidth='50px'
                >
                    <RightSideBar collapsed={collapsedRight} />
                </Sider>
            )}
        </Layout>
    );
}
