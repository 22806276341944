import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import BrandHeader from '../../brandHeader/BrandHeader';
import { Menu } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import UserSubscriptionPlanModal from 'ck_commun/src/app/modManager/components/UserSubscriptionPlanModal';

export default function LeftSideBar({
    appName,
    applogo,
    collapsed,
    menuItems,
    defaultActiveKey,
    defaultOpenItemsKeys,
}) {
    const menuKey = localStorage.getItem('activeMenuKey');
    const extractParentKey = (itemKey) => {
        //extractParentKey retourne la clé parente "history" à partir de la clé menu "history/sales"
        return itemKey?.split('/')[0];
    };
    const { selectedGroup, loadingGroup } = useSelector(
        (state) => state.manager,
    );
    const [isPlansModalVisible, setIsPlansModalVisible] = useState(false);
    const [openKeys, setOpenKeys] = useState(
        [extractParentKey(menuKey)] || defaultOpenItemsKeys || [],
    ); //menu(ayant des sous-menus) ouvert par défaut
    const [activeMenuKey, setActiveMenuKey] = useState(
        menuKey || defaultActiveKey || menuItems[0]?.key,
    ); //menu actif

    // handleOpenKeysChange to Handle opening/closing of submenu items
    const handleOpenKeysChange = (keys) => {
        setOpenKeys(keys);
    };

    return (
        <>
            <div className=' flex flex-col items-center mx-2 mt-2 mb-10'>
                <BrandHeader
                    logoSrc={applogo}
                    appName={appName}
                    collapsed={collapsed}
                />

                {!collapsed && !loadingGroup && (
                    <div className='text-white font-semibold text-lg text-center'>
                        {selectedGroup?.name}
                    </div>
                )}

                <div
                    className='cursor-pointer '
                    onClick={() => setIsPlansModalVisible(true)}
                >
                    {selectedGroup?.plan?.icon && (
                        <img
                            src={selectedGroup?.plan?.icon}
                            alt={selectedGroup?.plan?.plan_name}
                            style={{ filter: 'brightness(100)' }}
                            width={'20em'}
                        />
                    )}

                    {!collapsed && (
                        <span className='text-white text-base ml-2 font-light leading-6 align-bottom drop-shadow-md text-center text-ellipsis overflow-hidden ...'>
                            {selectedGroup?.company?.plan_name}
                        </span>
                    )}
                </div>
            </div>

            <Menu
                items={menuItems}
                openKeys={openKeys}
                onOpenChange={handleOpenKeysChange}
                collapsed={collapsed}
                currentActiveMenuKey={activeMenuKey}
            />

            {isPlansModalVisible && (
                <UserSubscriptionPlanModal
                    modalVisible={isPlansModalVisible}
                    setModalVisible={setIsPlansModalVisible}
                />
            )}
        </>
    );
}
